import EbayCallbackPage from './EbayCallbackPage';
import EbayDeclinedCallbackPage from './EbayDeclinedCallbackPage';
import GmailCallbackPage from './GmailCallbackPage';

export default [
    {
        path: '/callback/ebay',
        middleware: ['auth'],
        component: EbayCallbackPage,
    },
    {
        path: '/callback/ebay/declined',
        middleware: ['auth'],
        component: EbayDeclinedCallbackPage,
    },
    {
        path: '/callback/gmail',
        middleware: ['auth'],
        component: GmailCallbackPage,
    },
];
