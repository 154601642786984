const STATUS_OPEN = 'open';
const STATUS_AWAITING_REPLY = 'awaiting_reply';
const STATUS_RESOLVED = 'resolved';
const STATUS_ARCHIVE = 'archive';
const STATUS_SPAM = 'spam';

const VIEW_TYPE_PILLS = 'split';
const VIEW_TYPE_POPOUT = 'popout';
const VIEW_TYPE_TABLE = 'table';

const PRIORITY_URGENT = 'urgent';
const PRIORITY_HIGH = 'high';
const PRIORITY_MEDIUM = 'medium';
const PRIORITY_LOW = 'low';

const COLOR_SEA_GREEN = 'sea_green'; // 4BF0C3
const COLOR_ELECTRIC_BLUE = 'electric_blue'; // 7CECED
const COLOR_FRENCH_SKY_BLUE = 'french_sky_blue'; // 71B8FF
const COLOR_PALE_VIOLET = 'pale_violet'; // A298FF
const COLOR_FLAVESCENT = 'flavescent'; // FFEBA3
const COLOR_FRENCH_PINK = 'french_pink'; // FF77A8
const COLOR_BURNT_SIENNA = 'burnt_sienna'; // E37050
const COLOR_GUNMETAL = 'gunmetal'; // 2D3436
const COLOR_MOUNTAIN_MEADOW = 'mountain_meadow'; // 00B994
const COLOR_EDGE_BLUE = 'edge_blue'; // 0082E6
const COLOR_MAJORELLE_BLUE = 'majorelle_blue'; // 6C56EB
const COLOR_MELON = 'melon'; // FCB19E

const SORT_TYPE_LAST_MESSAGE = 'last_message';
const SORT_TYPE_DATE_CREATED = 'date_created';
const SORT_TYPE_DATE_MODIFIED = 'date_modified';
const SORT_TYPE_DUE_AT = 'due_at';
const SORT_TYPE_RESOLUTION_AT = 'resolution_at';
const SORT_TYPE_LAST_UNREPLIED = 'last_unreplied_message';

// const SORT_TYPE_LAST_REPLY = 'last_reply';
// const SORT_TYPE_PRIORITY = 'priority';
// const SORT_TYPE_STATUS = 'status';

const KEY_STATUS = 'status';
const KEY_PRIORITY = 'priority';
const KEY_TICKET_TYPE_ID = 'ticket_type_id';
const KEY_SOURCE_ID = 'source_id';

const EVENT_TICKET_MESSAGE_SENT = 'Ticket\\TicketMessageSent';
const EVENT_TICKET_UPDATED = 'Ticket\\TicketUpdated';
const EVENT_TICKET_UPDATED_WITHOUT_LISTENER = 'Ticket\\TicketUpdatedWithoutListener';
const EVENT_TICKET_RECEIVED = 'Ticket\\TicketReceived';
const EVENT_TICKET_MESSAGE_RECEIVED = 'Ticket\\TicketMessageReceived';
const EVENT_TICKET_MESSAGE_DELETED = 'Ticket\\TicketMessageDeleted';
const EVENT_USER_ACTIVITY = 'Activity\\UserActivity';

const ROLE_ADMIN = 'admin';
const ROLE_USER = 'user';

const PROVIDER_AMAZON = 'amazon';
const PROVIDER_EBAY = 'ebay';
const PROVIDER_EMAIL = 'email';
const PROVIDER_FRUUGO = 'fruugo';
const PROVIDER_WHATSAPP = 'whatsapp';
const PROVIDER_SMS = 'sms';
const PROVIDER_MANUAL = 'manual';
const PROVIDER_SHOPIFY = 'shopify';
const PROVIDER_MAGENTO = 'magento';
const PROVIDER_WOO_COMMERCE = 'woo_commerce';
const PROVIDER_BIG_COMMERCE = 'big_commerce';
const PROVIDER_EKM = 'ekm';
const PROVIDER_OPENCART = 'opencart';
const PROVIDER_PRESTASHOP = 'prestashop';
const PROVIDER_CUBECART = 'cubecart';

const AGENT_LIST_TYPE_ACTIVE = 'active';
const AGENT_LIST_TYPE_PENDING = 'pending_activation';

const ACTIVITY_TICKET_RESOLVED = 'activity_ticket_resolved';
const ACTIVITY_TICKET_REOPEN = 'activity_ticket_reopen';
const ACTIVITY_TICKET_STATUS_CHANGED = 'activity_ticket_status_changed';
const ACTIVITY_TICKET_PRIORITY_CHANGED = 'activity_ticket_priority_changed';
const ACTIVITY_TICKET_ASSIGNED_TO_YOU = 'activity_ticket_assigned_to_you';
const ACTIVITY_TICKET_UNASSIGNED_TO_YOU = 'activity_ticket_unassigned_to_you';
const ACTIVITY_TICKET_REPLIED = 'activity_ticket_replied'; // user name
const ACTIVITY_TICKET_MESSAGE_RECEIVED = 'activity_ticket_message_received'; // customer name
const ACTIVITY_INVITATION_RECEIVED = 'activity_invitation_received';
const ACTIVITY_PRIVATE_NOTE_ADDED = 'private_note_added';

const ENVIRONMENT_APP_URL = 'REACT_APP_ENVIRONMENT';

const LOCAL_ENVIRONMENT = 'local';
const DEVELOPMENT_ENVIRONMENT = 'development';
const PRODUCTION_ENVIRONMENT = 'production';

const TYPE_MESSAGE = 'message';
const TYPE_NOTE = 'note';

const CALLBACK_EBAY_CHANNEL_ID = 'callback_ebay_channel_id';
const CALLBACK_GMAIL_CHANNEL_ID = 'callback_gmail_channel_id';

const STORAGE_KEY_VIEW_TYPE = 'helpdesk_home_view_type';
const STORAGE_KEY_FILTERS = 'helpdesk_home_filters';
const STORAGE_KEY_SORT = 'helpdesk_home_sort';
const STORAGE_KEY_RESIZE_WIDTH = 'helpdesk_home_ticket_list_resize_width';
const STORAGE_KEY_ACCEPT_INVITATION = 'helpdesk_accept_invitation_url';

const INTERVAL_MINUTE = 'i';
const INTERVAL_HOUR = 'h';
const INTERVAL_DAY = 'd';

const OPERATIONAL_CALENDAR_TIME = 'calendar';
const OPERATIONAL_WORK_TIME = 'work';

const MAIL_SERVER_TYPE = {
    POP3: 'pop3',
    IMAP: 'imap',
};

const MAIL_ENCRYPTION = {
    TLS: 'tls',
    SSL: 'ssl',
};

const ORDER_BY_TYPES = {
    ASC: 'asc',
    DESC: 'desc',
};

const BREAKPOINTS = {
    md: 768,
    lg: 992,
    xl: 1200,
};

const DAYS = {
    MONDAY: 'monday',
    TUESDAY: 'tuesday',
    WEDNESDAY: 'wednesday',
    THURSDAY: 'thursday',
    FRIDAY: 'friday',
    SATURDAY: 'saturday',
    SUNDAY: 'sunday',
};

const AVAILABLE_VARIABLES = ['ticketId', 'subject', 'customer', 'agent', 'agent_email'];

const DAYS_OF_WEEK = [
    {
        id: 1,
        name: DAYS.MONDAY,
    },
    {
        id: 2,
        name: DAYS.TUESDAY,
    },
    {
        id: 3,
        name: DAYS.WEDNESDAY,
    },
    {
        id: 4,
        name: DAYS.THURSDAY,
    },
    {
        id: 5,
        name: DAYS.FRIDAY,
    },
    {
        id: 6,
        name: DAYS.SATURDAY,
    },
    {
        id: 0,
        name: DAYS.SUNDAY,
    },
];

const AFTER_SEND_CLOSE = 'close';

const RULE_FIELDS = [
    'status',
    'customer_name',
    'customer_email',
    'priority',
    'subject',
    'channel',
    'body_of_ticket',
]; // 'ticket_type'];

const RULE_ACTION_TASKS = [
    'set_status',
    'set_priority',
    'set_ticket_type',
    'reply',
    'forward',
    'forward_first',
];

const RULE_OPERATORS = ['equal', 'not_equal', 'contains', 'not_contains'];

const RULE_CONNECTIVE = ['and', 'or'];

const DATE_INPUT_FORMAT = 'YYYY-MM-DD';

const DESPATCH_CLOUD_VERSIONS = [
    { title: '3.4', value: '3_4' },
    { title: '3.5', value: '3_5' },
    { title: '3.6', value: '3_6' },
];

export default {
    STATUS_OPEN,
    STATUS_AWAITING_REPLY,
    STATUS_RESOLVED,
    STATUS_ARCHIVE,
    STATUS_SPAM,
    statuses: [STATUS_OPEN, STATUS_AWAITING_REPLY, STATUS_RESOLVED, STATUS_ARCHIVE, STATUS_SPAM],
    VIEW_TYPE_PILLS,
    VIEW_TYPE_POPOUT,
    VIEW_TYPE_TABLE,
    viewTypes: [VIEW_TYPE_PILLS, VIEW_TYPE_POPOUT, VIEW_TYPE_TABLE],
    PRIORITY_URGENT,
    PRIORITY_HIGH,
    PRIORITY_MEDIUM,
    PRIORITY_LOW,
    priorities: [PRIORITY_URGENT, PRIORITY_HIGH, PRIORITY_MEDIUM, PRIORITY_LOW],
    COLOR_SEA_GREEN,
    COLOR_ELECTRIC_BLUE,
    COLOR_FRENCH_SKY_BLUE,
    COLOR_PALE_VIOLET,
    COLOR_FLAVESCENT,
    COLOR_FRENCH_PINK,
    COLOR_BURNT_SIENNA,
    COLOR_GUNMETAL,
    COLOR_MOUNTAIN_MEADOW,
    COLOR_EDGE_BLUE,
    COLOR_MAJORELLE_BLUE,
    COLOR_MELON,
    colors: [
        COLOR_SEA_GREEN,
        COLOR_ELECTRIC_BLUE,
        COLOR_FRENCH_SKY_BLUE,
        COLOR_PALE_VIOLET,
        COLOR_FLAVESCENT,
        COLOR_FRENCH_PINK,
        COLOR_BURNT_SIENNA,
        COLOR_GUNMETAL,
        COLOR_MOUNTAIN_MEADOW,
        COLOR_EDGE_BLUE,
        COLOR_MAJORELLE_BLUE,
        COLOR_MELON,
    ],
    SORT_TYPE_LAST_MESSAGE,
    SORT_TYPE_DATE_CREATED,
    SORT_TYPE_DATE_MODIFIED,
    SORT_TYPE_LAST_UNREPLIED,
    SORT_TYPE_DUE_AT,
    SORT_TYPE_RESOLUTION_AT,
    sortTypes: [
        SORT_TYPE_LAST_MESSAGE,
        SORT_TYPE_DATE_CREATED,
        SORT_TYPE_DATE_MODIFIED,
        SORT_TYPE_DUE_AT,
        SORT_TYPE_RESOLUTION_AT,
        SORT_TYPE_LAST_UNREPLIED,
        // SORT_TYPE_PRIORITY,
        // SORT_TYPE_STATUS,
    ],
    roles: [ROLE_USER, ROLE_ADMIN],
    KEY_STATUS,
    KEY_PRIORITY,
    KEY_TICKET_TYPE_ID,
    KEY_SOURCE_ID,
    EVENT_TICKET_UPDATED,
    EVENT_TICKET_UPDATED_WITHOUT_LISTENER,
    EVENT_TICKET_MESSAGE_SENT,
    EVENT_TICKET_RECEIVED,
    EVENT_TICKET_MESSAGE_RECEIVED,
    EVENT_TICKET_MESSAGE_DELETED,
    EVENT_USER_ACTIVITY,
    events: [
        EVENT_TICKET_UPDATED,
        EVENT_TICKET_UPDATED_WITHOUT_LISTENER,
        EVENT_TICKET_MESSAGE_SENT,
        EVENT_TICKET_RECEIVED,
        EVENT_TICKET_MESSAGE_RECEIVED,
        EVENT_TICKET_MESSAGE_DELETED,
    ],
    PROVIDER_AMAZON,
    PROVIDER_EBAY,
    PROVIDER_EMAIL,
    PROVIDER_FRUUGO,
    PROVIDER_WHATSAPP,
    PROVIDER_SMS,
    PROVIDER_MANUAL,
    PROVIDER_SHOPIFY,
    PROVIDER_MAGENTO,
    PROVIDER_WOO_COMMERCE,
    PROVIDER_BIG_COMMERCE,
    PROVIDER_EKM,
    PROVIDER_OPENCART,
    PROVIDER_PRESTASHOP,
    PROVIDER_CUBECART,
    providers: [
        PROVIDER_AMAZON,
        PROVIDER_EBAY,
        PROVIDER_EMAIL,
        PROVIDER_FRUUGO,
        PROVIDER_WHATSAPP,
        PROVIDER_SMS,
        PROVIDER_MANUAL,
        PROVIDER_SHOPIFY,
        PROVIDER_MAGENTO,
        PROVIDER_WOO_COMMERCE,
        PROVIDER_BIG_COMMERCE,
        PROVIDER_EKM,
        PROVIDER_OPENCART,
        PROVIDER_PRESTASHOP,
        PROVIDER_CUBECART,
    ],
    AGENT_LIST_TYPE_ACTIVE,
    AGENT_LIST_TYPE_PENDING,
    activities: {
        ACTIVITY_TICKET_RESOLVED,
        ACTIVITY_TICKET_REOPEN,
        ACTIVITY_TICKET_STATUS_CHANGED,
        ACTIVITY_TICKET_PRIORITY_CHANGED,
        ACTIVITY_TICKET_ASSIGNED_TO_YOU,
        ACTIVITY_TICKET_UNASSIGNED_TO_YOU,
        ACTIVITY_TICKET_REPLIED,
        ACTIVITY_TICKET_MESSAGE_RECEIVED,
        ACTIVITY_INVITATION_RECEIVED,
        ACTIVITY_PRIVATE_NOTE_ADDED,
    },
    ENVIRONMENT_APP_URL,
    LOCAL_ENVIRONMENT,
    DEVELOPMENT_ENVIRONMENT,
    PRODUCTION_ENVIRONMENT,
    TYPE_MESSAGE,
    TYPE_NOTE,
    MAIL_SERVER_TYPE,
    MAIL_ENCRYPTION,
    ORDER_BY_TYPES,
    CALLBACK_EBAY_CHANNEL_ID,
    CALLBACK_GMAIL_CHANNEL_ID,
    BREAKPOINTS,
    STORAGE_KEY_VIEW_TYPE,
    STORAGE_KEY_FILTERS,
    STORAGE_KEY_SORT,
    STORAGE_KEY_RESIZE_WIDTH,
    STORAGE_KEY_ACCEPT_INVITATION,
    ROLE_ADMIN,
    intervals: {
        INTERVAL_MINUTE,
        INTERVAL_HOUR,
        INTERVAL_DAY,
    },
    operationalHours: {
        OPERATIONAL_CALENDAR_TIME,
        OPERATIONAL_WORK_TIME,
    },
    DAYS,
    DAYS_OF_WEEK,
    AVAILABLE_VARIABLES,
    AFTER_SEND_CLOSE,
    rules: {
        rule_fields: RULE_FIELDS,
        rule_operators: RULE_OPERATORS,
        rule_action_tasks: RULE_ACTION_TASKS,
        rule_connective: RULE_CONNECTIVE,
    },
    DATE_INPUT_FORMAT,
    DESPATCH_CLOUD_VERSIONS,
};
